//src/api/recomapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
//获取推荐商品列表接口
export function getarticleData(obj,url) {
    return request({
        url: '/api/admin/article?',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增推荐商品接口
export function postaddarticle(obj,url) {
    return request({
        url: '/api/admin/article/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑推荐商品接口
export function posteditarticle(obj,url) {
    return request({
        url: '/api/admin/article/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除推荐商品接口
export function postdelarticle(obj,url) {
    return request({
        url: '/api/admin/article/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除推荐商品接口
export function postdeladelTags(obj,url) {
    return request({
        url: '/api/admin/article/delTags',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}


//获取产品绑定列表接口
export function getarticleTagData(obj,url) {
    return request({
        url: '/api/admin/article/tag',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增产品绑定接口
export function postaddarticleTag(obj,url) {
    return request({
        url: '/api/admin/article/tag/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑产品绑定接口
export function posteditarticleTag(obj,url) {
    return request({
        url: '/api/admin/article/tag/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除产品绑定接口
export function postdelarticleTag(obj,url) {
    return request({
        url: '/api/admin/article/tag/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}




//获取产品绑定列表接口
export function getCategoryData(obj,url) {
    return request({
        url: '/api/admin/article/category',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增产品绑定接口
export function postaddCategory(obj,url) {
    return request({
        url: '/api/admin/article/category/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑产品绑定接口
export function posteditCategory(obj,url) {
    return request({
        url: '/api/admin/article/category/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除产品绑定接口
export function postdelCategory(obj,url) {
    return request({
        url: '/api/admin/article/category/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

