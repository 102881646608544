<template>
  <div class="about">
    <el-table
        :data="tableData"
        size="medium"
        height="calc(100vh - 145px)"
        row-key="id"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
          prop="name"
          min-width="300"
          label="分类名称">
      </el-table-column>
      <el-table-column
          prop="id"
          width="70"
          label="ID">
      </el-table-column>
      <el-table-column
          prop="text"
          width="70"
          label="平台">
        <template #default="scope">
          <span v-if="scope.row.platform==0">客户端</span>
          <span v-else>师傅端</span>
        </template>
      </el-table-column>
      <el-table-column prop="product_logo"  width="80" label="图标" >
        <template #default="scope">
          <div style="height: 100%;display: flex;align-items:center;">
            <el-image
                v-if="scope.row.icon"
                style="width: 40px; height: 40px"
                :src="scope.row.icon"
                :preview-src-list="[scope.row.icon]">
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          width="80"
          prop="sequence"
          label="排序">
      </el-table-column>
      <el-table-column
          width="166"
          prop="create_time"
          label="添加时间">
      </el-table-column>
      <el-table-column
          prop="text"
          width="120"
          fixed="right"
          label="操作">
        <template #header>
          <el-button  @click="add()" size="mini" type="primary" style="margin:10px 0;">新增</el-button>
        </template>
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
              <!--              <el-button size="mini" type="primary" icon="el-icon-plus" @click="add(scope.row,'nowadd')"></el-button>-->
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
              <el-button size="mini"  type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">
        <el-form-item label="图标地址">
          <el-upload
              style=" width: 100px;height: 100px"
              class="avatar-uploader"
              :action="UpURL"
              :show-file-list="false"
              :http-request="httpRequest"
              :before-upload="beforeAvatarUpload">
            <img v-if="ruleForm.icon" :src="ruleForm.icon" class="avatar" />
            <i v-else  class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <p style="color: #999999"><span style="font-size: 16px" class="el-icon-info"> </span>图片尺寸 宽 200 X 高 200</p>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="上级名称" prop="father_id">
          <el-cascader
              placeholder=""
              ref="cascaderHandle"
              style="width: 100%"
              :options="areaArr"
              v-model="ruleForm.father_id"
              :props="{
                          label: 'name',
                          value: 'id',
                          children: 'children',
                          checkStrictly: true
                          }"
              :filterable="true">
          </el-cascader>
        </el-form-item>
        <el-form-item label="平台" prop="platform">
            <el-select style="width:100%" v-model="ruleForm.platform" >
              <el-option
                  v-for="item in platformArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="排序" >
          <el-input-number style="width: 100%" :min="0" controls-position="right" v-model="ruleForm.sequence"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import {getArea, getCharge, getIMG, getUQIMGurl} from "@/api/common";
import * as axios from "@/api/articleApi"//本页面接口
export default {
  name: 'brand',
  data(){
    return{
      UpURL:'',
      login:false,
      drawer:false,
      tableData:[],
      fatherArr:[],
      areaArr:[],
      statusArr:[
        {
          id:0,
          name:'待开通',
        },
        {
          id:1,
          name:'已开通',
        },
        {
          id:2,
          name:'已停止',
        },
      ],
      platformArr:[
        {
          id:0,
          name:'客户端',
        },
        {
          id:1,
          name:'师傅端',
        },
      ],

      ruleForm:{
        name:'',
        icon:'',
        sequence:0,
        father_id:'',
        platform:'',
      },
      rules:{
        name: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
        father_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        platform: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      }
    }
  },
  created() {
    this.onTable()
  },
  methods:{
    //LOGO上传图片方法
    httpRequest(options) {
      this.login=true
      // console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
        getUQIMGurl({bucket:'product',ext:'.'+src.substr(6)}).then((res)=>{
          // console.log('获取URL',res)
          if(res.code==0){
            this.UpURL=res.data.upload_url
            let url=res.data.preview_url
            // console.log(this.UpURL)
            getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
              resolve(res)
              this.ruleForm.icon=url
              this.login=false
            }).catch((err)=>{
              reject(err)
              this.login=false
            })
          }else {
            this.$message.error('获取上传地址失败！！')
            this.login=false
          }
        })

      })
    },
    //
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||
          file.type === "image/png" ||
          file.type === "image/jpg"||
          file.type === "image/gif";
      // const isLt2M = file.size / 1024 / 1024 < 4
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG、 格式!')
      }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 4MB!')
      // }
      return isJPG

      // && isLt2M
    },
    /*图片上传*/
    onTable(){
      axios.getCategoryData().then((res)=>{
        console.log(res)
        this.tableData=res.data
        this.areaArr=JSON.parse(JSON.stringify(res.data))
        this.areaArr.unshift({
          name:'顶层分类',
          id:0
        })
        console.log('this.tableData',this.tableData)
      }).catch((err)=>{

      })
    },

    add(row,val){
      this.ruleForm= {
        name:'',
        icon:'',
        sequence:0,
        father_id:'',
        platform:'',
      }
      // if(val=='nowadd'){
      //   this.ruleForm.father_info=row.id
      // }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.name=row.name
      this.ruleForm.icon=row.icon
      this.ruleForm.sequence=row.sequence
      this.ruleForm.father_id=row.father_id
      this.ruleForm.platform=row.platform
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      // this.ruleForm.father_info=JSON.stringify(this.ruleForm.father_info)
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postaddCategory(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditCategory(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }


        } else {
          that.drawer=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelCategory({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
</style>